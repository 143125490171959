import React from "react";
import "./Modal.css";

function Modal() {
  return (
    <div className="modal">
      <h1 className="modal-title">Húzi</h1>
      <p className="modal-text">
        Meet Huzi, a charming dog whose most distinctive feature is his adorable
        little "mustache." This pup's name is inspired by the Chinese word 胡子
        (húzi), which refers to facial hair like beards, mustaches, or whiskers
      </p>
      <div className="modal-divider"></div>
      <div className="modal-buttons">
        <button
          className="modal-button"
          onClick={() => window.open("https://x.com/HuziOnTron", "_blank")}
        >
          <img src="/twitter-logo.png" alt="Twitter" className="button-logo" />
          Twitter
        </button>
        <button
          className="modal-button"
          onClick={() => window.open("https://t.me/HuziOnTron", "_blank")}
        >
          <img
            src="/telegram-logo.png"
            alt="Telegram"
            className="button-logo"
          />
          Telegram
        </button>
      </div>
      <div className="modal-footer">
        <p className="footer-text">CA: TV3BRXie6cxY3mWvTa3GAYoC71bSezsFRC</p>
      </div>
    </div>
  );
}

export default Modal;

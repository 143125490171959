import React from "react";
import "./App.css";
import Modal from "./Modal";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/header-image.png" alt="Header" className="header-image" />
      </header>
      <div className="side-image-container left-side">
        <div className="thread"></div>
        <img src="/side-image-left.png" alt="Left" className="side-image" />
      </div>
      <div className="side-image-container right-side">
        <div className="thread"></div>
        <img src="/side-image-right.png" alt="Right" className="side-image" />
      </div>
      <Modal />
    </div>
  );
}

export default App;
